<template>
  <div>
    <client-only>
      <component :is="componentInstance" />
      <LoadingOverlay v-if="loader.isLoading" />
    </client-only>
  </div>
</template>

<script>
import LoadingOverlay from '~/components/common/LoadingOverlay.vue'
export default {
  components: {
    LoadingOverlay
  },
  async fetch({ store, params, query, isMobile }) {
    // await store.dispatch('gallery/getProductGallery')
    await store.dispatch('advertises/getAdvertiseActive')
    await store.dispatch('information/getTabIndex')
    await store.dispatch('information/getWebSetting', {
      pageName: 'หน้าแรก'
    })
  },
  data() {
    return {
      loader: {
        isLoading: false,
        fullPage: true,
        width: 60,
        height: 60,
        spinnerColor: '#ffab00'
      }
    }
  },
  computed: {
    componentInstance({ context }) {
      return () =>
        import(`@/components/pages/index/${this.$store.state.layout}`)
    }
  },
  created() {
    this.$axios.interceptors.request.use(
      (config) => {
        // trigger 'loading=true' event here
        this.loader.isLoading = true
        return config
      },
      (error) => {
        // trigger 'loading=false' event here
        return Promise.reject(error)
      }
    )

    this.$axios.interceptors.response.use(
      (response) => {
        // trigger 'loading=false' event here
        this.loader.isLoading = false
        return response
      },
      (error) => {
        // trigger 'loading=false' event here
        this.loader.isLoading = false
        return Promise.reject(error)
      }
    )
  },
  beforeMount() {
    // [Start]-> Datalayer push (All Pages)
    this.$gtm.push({
      event: 'pageInfo', // Event Type
      page: {
        type: 'home' // ประเภทของหน้า
      }
    })
    // [End]-> Datalayer push (All Pages)
  },
  head() {
    return {
      title: this.$store.state.information.siteData.header_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.information.siteData.meta_description
        },
        {
          hid: 'keyword',
          name: 'keyword',
          content: this.$store.state.information.siteData.meta_keywords
        }
      ]
    }
  },
  layout(context) {
    return context.layout
  }
}
</script>
